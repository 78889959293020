import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Helmet from 'react-helmet'
import PageTitle from '../../components/PageTitle'
// import DocsSearch from '../../components/DocsSearch'
import LandingMenu from '../../components/LandingMenu'
import Cta from '../../components/Cta'
import './DocsLanding.scss'

class DocsLanding extends React.Component {
  render() {
    const { frontmatter } = this.props.data.pageMarkdown;
    const { frontmatter: menu } = this.props.data.file.childMarkdownRemark;
    const { frontmatter: cta } = this.props.data.ctaMarkdown;

    return (
      <Layout color="4">
        <Helmet title={`${frontmatter.title} | GrantCycle.io Documentation`}>
          <meta name="description" content={ frontmatter.subtitle } />
        </Helmet>
        <PageTitle className="DocsLanding__title">
          <h1>Documentation</h1>
          <div className="DocsLanding__title-wrapper">
            <h2>{ frontmatter.title }</h2>
            <div className="DocsLanding__subtitle">
              { frontmatter.subtitle }
            </div>
          </div>
        </PageTitle>
        <section className="DocsLanding__content-wrapper">
          <div className="DocsLanding__content">
            {
              //<div className="DocsLanding__search-wrapper">
              //  <DocsSearch />
              //</div>
            }
            <LandingMenu data={ menu } pathPrefix="docs" />
          </div>
        </section>
        <Cta
          title={ cta.title }
          buttonLink={ cta.button.link }
          buttonText={ cta.button.text }
        />
      </Layout>
    )
  }
}

export default DocsLanding

export const docsLandingQuery = graphql`
  query DocsLanding($path: String!) {
    pageMarkdown: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        title
        subtitle
      }
    }
    ...docsMenu
    ...cta
  }
`
